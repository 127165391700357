import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentRef,
} from 'overlayscrollbars-react';
import React, {
  forwardRef,
  PropsWithChildren,
  UIEventHandler,
  useEffect,
  useRef,
} from 'react';

interface ScrollBoxProps {
  className?: string;
  style?: React.CSSProperties;
  axis: 'x' | 'y' | 'xy';
  onScroll?: UIEventHandler<HTMLDivElement>;
}

export const ScrollBox = forwardRef<
  HTMLElement,
  PropsWithChildren<ScrollBoxProps>
>((props, parentRef) => {
  const { className, style, children, axis, onScroll } = props;

  const osRef = useRef<OverlayScrollbarsComponentRef>(null);

  useEffect(() => {
    const scrollElement =
      osRef.current?.osInstance()?.elements().viewport ?? null;
    if (parentRef) {
      if (typeof parentRef === 'function') {
        parentRef(scrollElement);
      } else {
        parentRef.current = scrollElement;
      }
    }
  }, [osRef, parentRef]);

  useEffect(() => {
    const scrollElement =
      osRef.current?.osInstance()?.elements().viewport ?? null;
    if (scrollElement && onScroll) {
      const scrollHandler = (event: Event) => {
        onScroll({
          ...event,
          currentTarget: event.target,
        } as unknown as React.UIEvent<HTMLDivElement>);
      };
      scrollElement.addEventListener('scroll', scrollHandler);
      return () => scrollElement.removeEventListener('scroll', scrollHandler);
    }
  }, [osRef, onScroll]);

  return (
    <OverlayScrollbarsComponent
      ref={osRef}
      className={className}
      style={style}
      options={{
        overflow: {
          x: axis === 'xy' || axis === 'x' ? 'scroll' : 'hidden',
          y: axis === 'xy' || axis === 'y' ? 'scroll' : 'hidden',
        },
        scrollbars: {
          theme: 'os-theme-light',
        },
      }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
});
