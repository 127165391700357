import React from 'react';
import { Menu } from '@reach/menu-button';
import { Box } from '@webMolecules/Box/Box';
import { Card } from '@webMolecules/Card/Card';
import ReportDiagramWrapper from '@webViews/Pages/Study/Diagram/ReportDiagramWrapper';
import {
  userColourPaletteActive,
  userColourPaletteLight,
} from '@appCanvas/interfaces/types/ColourPalette.type';
import { t, tDate } from '@webInterfaces/I18n';
import { Icon } from '@webMolecules/Icon/Icon';
import { timeFromX } from '@helpers/datetime';
import { cnames } from '@helpers/cnames';
import styles from './comparison.scss';

interface ComparisonDiagramProps {
  isPrevious?: boolean;
  date: Date | null;
  onlyShowMatchedLesion?: boolean;
  editable?: boolean;
}

export const ComparisonDiagram: React.FC<ComparisonDiagramProps> = ({
  isPrevious,
  date,
  onlyShowMatchedLesion,
  editable,
}) => {
  const comparisonDiagram = cnames(styles.comparisonDiagram, {
    [styles.canvasBorder]: !editable,
  });

  return (
    <div className={comparisonDiagram}>
      <Card
        padding="none"
        display="flex"
        flexDirection="column"
        transparent={isPrevious || editable}
      >
        <div className="relative self-center">
          {isPrevious ? (
            <div>
              <Menu>
                <ComparisonDiagramLabel
                  editable={editable}
                  isPrevious={isPrevious}
                  date={date}
                />
              </Menu>
            </div>
          ) : (
            <ComparisonDiagramLabel isPrevious={isPrevious} date={date} />
          )}
        </div>

        <ReportDiagramWrapper
          canvasColorPalette={
            editable ? userColourPaletteActive : userColourPaletteLight
          }
          showCanvasOnly={true}
          isPrevious={isPrevious}
          onlyShowMatchedLesion={onlyShowMatchedLesion}
        />
      </Card>
    </div>
  );
};

interface ComparisonDiagramLabelProps {
  editable?: boolean;
  isPrevious?: boolean;
  date: Date | null;
}

const ComparisonDiagramLabel: React.FC<ComparisonDiagramLabelProps> = ({
  editable = false,
  isPrevious,
  date,
}) => {
  const label = isPrevious
    ? t('pages.study.comparison.previous')
    : t('pages.study.comparison.current');
  const labelPrevious = cnames(styles.labelPrevious, {
    [styles.pdfLabel]: !editable,
  });
  return (
    <div className="flex h-[40px] gap-xs overflow-hidden rounded-b-m">
      <Box
        paddingY="xxs"
        paddingX="m"
        display="flex"
        gap="xs"
        alignItems="center"
        className={!isPrevious ? styles.labelCurrent : labelPrevious}
      >
        <span className="text-body2">{label}</span>
        <span className="text-body">|</span>
        <span className="text-display6">{date ? tDate(date) : ''}</span>
      </Box>
    </div>
  );
};

interface ComparisonDiagramSectionProps {
  previousDate: Date | null;
  currentDate: Date | null;
  onlyShowMatchedLesion?: boolean;
  fullPageWidth?: boolean;
  lightTheme?: boolean;
}

export const ComparisonDiagramSection: React.FC<
  ComparisonDiagramSectionProps
> = ({
  previousDate,
  currentDate,
  onlyShowMatchedLesion = false,
  fullPageWidth = true,
  lightTheme = false,
}) => {
  const duration = timeFromX(previousDate, currentDate);
  const durationClass = cnames(
    'absolute inset-x-0 m-auto flex h-[40px] w-fit items-center gap-xs border-b-2 border-normal bg-neutral-900 px-m text-muted',
    {
      'border-t-2 bg-gradient-to-r from-alt-50 to-white': lightTheme,
    }
  );

  return (
    <div className={lightTheme ? '' : 'border-t-2 border-normal'}>
      <div
        className={
          fullPageWidth
            ? styles.comparisonDiagramSection
            : styles.comparisonDiagramContainer
        }
      >
        <ComparisonDiagram
          date={previousDate}
          isPrevious
          onlyShowMatchedLesion={onlyShowMatchedLesion}
          editable={!lightTheme}
        />
        {duration && (
          <div className={durationClass}>
            <Icon name="timer" />
            <span className="text-body2 text-muted">{duration}</span>
          </div>
        )}
        <ComparisonDiagram date={currentDate} editable={!lightTheme} />
      </div>
    </div>
  );
};
