import React from 'react';
import { Icon } from '@webMolecules/Icon/Icon';
import {
  getIconByIconStyle,
  getColorFromLabel,
  labelColor,
} from '@webOrganisms/ResultTable/FindingsTable/Shared';
export interface ArrowIconProps {
  previousValue: number | null | undefined;
  currentValue: number | undefined;
  type?: 'percentage' | 'absolute';
  previousDisplayValue?: string;
  currentDisplayValue?: string;
}

export const ArrowIcon: React.FC<ArrowIconProps> = ({
  previousValue,
  currentValue,
  type,
  previousDisplayValue,
  currentDisplayValue,
}) => {
  let defaultDifferenceValue = undefined;

  if (currentValue != undefined && previousValue != undefined) {
    defaultDifferenceValue = currentValue - previousValue;
  }
  const iconStyle = labelColor(Number(defaultDifferenceValue));

  const color = getColorFromLabel(iconStyle);

  const colorClassNames = {
    negative: 'text-success',
    positive: 'text-destructive',
    default: 'text-normal',
  };

  switch (type) {
    case 'absolute':
      const previous = previousDisplayValue || previousValue || '-';
      const current = currentDisplayValue || currentValue || '-';

      const icon =
        iconStyle == 'negative'
          ? 'arrow-down-right'
          : iconStyle == 'positive'
            ? 'arrow-up-right'
            : 'arrow-right';

      return (
        <div className="flex items-center">
          <span className="mx-xxs text-body2 font-bold">{previous}</span>
          <Icon size="small" name={icon} color={color}></Icon>
          <span className="mx-xxs text-body2 font-bold">{current}</span>
        </div>
      );

    case 'percentage':
    default:
      // To calculate percentage value
      let positivePercentage = '-.--';
      const validDiff =
        defaultDifferenceValue != undefined && previousValue != undefined;

      if (validDiff) {
        const percentageDifference = (defaultDifferenceValue &&
          (defaultDifferenceValue / previousValue) * 100) as number;
        positivePercentage = Math.abs(percentageDifference).toFixed(0);
      }
      const showIcon = Boolean(
        positivePercentage != '-.--' && iconStyle != 'default'
      );
      return (
        <div className={`flex items-center ${colorClassNames[iconStyle]}`}>
          {showIcon && (
            <Icon
              size="small"
              name={getIconByIconStyle(iconStyle)}
              color={color}
            ></Icon>
          )}
          &nbsp;
          <span className="text-body2">{`${positivePercentage || '-.--'}`}</span>
        </div>
      );
  }
};
