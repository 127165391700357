import React, { useEffect } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Text } from '@webMolecules/Text/Text';
import { Button } from '@webMolecules/Button/Button';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';
import {
  selectComparisonDateAndDuration,
  selectImpressions,
} from '@selectors/SessionSelectors';
import { checkIfAnySizeMissing } from '@webOrganisms/ResultTable/FindingsTable/SmallPartsStudyFindingHelper';
import { selectIncludedFindings } from '@selectors/FindingSelectors';
import { ScrollBox } from '@webMolecules/ScrollBox/ScrollBox';
import { MissingSizeDialog } from '../MissingSizeDialog';

interface RadiologyFooterProps {
  isComparisonAvailable: boolean;
  isComparisonPage: boolean;
  togglePage(): void;
}

export const RadiologyFooter: React.FC<RadiologyFooterProps> = ({
  isComparisonAvailable,
  isComparisonPage,
  togglePage,
}) => {
  const impressions = useSelector(selectImpressions);
  const findings = useSelector(selectIncludedFindings);

  const resetHistory = () => {}; //useDispatcher(actionHistoryUseCase.ResetActionHistory);
  const [open, setOpen] = React.useState(false);
  const [height, setHeight] = React.useState<number | null>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const [showMissingSizeDialog, setShowMissingSizeDialog] =
    React.useState(false);

  const hasFindingMissingSize = checkIfAnySizeMissing(findings);

  useEffect(() => {
    if (open && ref.current) {
      setHeight(ref.current.clientHeight);
    } else {
      setHeight(null);
    }
  }, [impressions, open]);

  const handleNext = () => {
    if (hasFindingMissingSize) {
      setShowMissingSizeDialog(true);
    } else {
      togglePage();
    }
  };

  if (isComparisonAvailable && !isComparisonPage) {
    return (
      <>
        <MissingSizeDialog
          isOpen={showMissingSizeDialog}
          onCancel={() => setShowMissingSizeDialog(false)}
          onConfirm={() => setShowMissingSizeDialog(false)}
          triggerBtnText={t(`generic.page.next`)}
        />
        <FollowUpFooter
          resetHistory={resetHistory}
          handleNext={handleNext}
          hasFindingMissingSize={hasFindingMissingSize}
        />
      </>
    );
  }

  const handleAccept = () => {
    if (hasFindingMissingSize) {
      setShowMissingSizeDialog(true);
    }
  };

  return (
    <>
      <MissingSizeDialog
        isOpen={showMissingSizeDialog}
        onCancel={() => setShowMissingSizeDialog(false)}
        onConfirm={() => setShowMissingSizeDialog(false)}
        triggerBtnText={t(`generic.dropdown.accept`)}
      />

      <div className="flex w-[862px] items-start gap-s self-center rounded-m bg-[rgb(33,56,70)]/[.40] px-xs py-xxs">
        <div className="flex flex-none items-center gap-xs ">
          <div className="*:hover:bg-neutral-400">
            <Button
              circle
              strong
              size="small"
              onClick={() => setOpen(!open)}
              iconBefore={
                <Icon
                  color="var(--ds-color-primary)"
                  name={open ? 'chevron-down' : 'chevron-up'}
                />
              }
            />
          </div>
          <Text type="display5" textColor="low">
            {t(`pages.study.radiologist.impressions`)}
          </Text>
          <Icon name="help-circle"></Icon>
        </div>

        <ScrollBox
          className="h-[3.3rem] flex-auto whitespace-pre-wrap px-m transition-[height] duration-200 ease-out "
          axis="y"
          style={height ? { height } : {}}
        >
          <div ref={ref}>
            <Text display="block" type="body2">
              {impressions}
            </Text>
          </div>
        </ScrollBox>
      </div>

      <ButtonContainer>
        {isComparisonPage ? (
          <Button
            strong
            iconBefore={<Icon name="arrow-left" size="medium" />}
            onClick={togglePage}
          >
            <Text type="display5"> {t(`generic.page.back`)}</Text>
          </Button>
        ) : (
          <Button
            strong
            iconBefore={<Icon name="rotate-ccw" size="medium" />}
            onClick={resetHistory}
          >
            <Text type="display5"> {t(`generic.dropdown.reset`)}</Text>
          </Button>
        )}
        <Button
          strong
          iconBefore={
            <Icon
              color="var(--ds-color-destructive-500)"
              name="x"
              size="medium"
            />
          }
        >
          <Text textColor="high" type="display5">
            {t(`generic.dropdown.reject`)}
          </Text>
        </Button>
        <Button
          strong
          intent={hasFindingMissingSize ? 'deactivated' : 'primary'}
          subtle={hasFindingMissingSize}
          iconBefore={<Icon name="check" size="medium" />}
          onClick={handleAccept}
        >
          <Text type="display5">{t(`generic.dropdown.accept`)}</Text>
        </Button>
      </ButtonContainer>
      {open && (
        <div className="absolute right-0 top-0 -translate-x-1/2 -translate-y-1/2">
          <Button
            circle
            strong
            size="small"
            onClick={() => setOpen(false)}
            iconBefore={<Icon name="x" />}
          />
        </div>
      )}
    </>
  );
};

function FollowUpFooter({
  resetHistory,
  handleNext,
  hasFindingMissingSize,
}: {
  resetHistory: () => void;
  handleNext: () => void;
  hasFindingMissingSize: boolean;
}) {
  const { date, duration } = useSelector(selectComparisonDateAndDuration);
  return (
    <>
      <div className="flex w-[862px] items-start gap-s self-center px-xs py-xxs ">
        <div className="flex flex-none items-center gap-xs">
          <Icon color="var(--ds-color-primary)" name="info" />
          <Text type="display5" textColor="low">
            {t('pages.study.comparison.footer.label')}
          </Text>
        </div>
        <div className="flex-auto px-m">
          <div>
            <Text type="body2">{t('pages.study.comparison.footer.info1')}</Text>
            <Text type="display6">&nbsp;{duration}&nbsp;</Text>
            <Text type="body2">{t('pages.study.comparison.footer.info2')}</Text>
            <Text type="display6">&nbsp;{date}&nbsp;</Text>
            <Text type="body2">{t('pages.study.comparison.footer.info3')}</Text>
          </div>
          <div>
            <Text type="body2">
              {t('pages.study.comparison.footer.action1')}
            </Text>
            <Text type="display6" textColor="low">
              &nbsp;{t('pages.study.comparison.footer.next')}&nbsp;
            </Text>
            <Text type="body2">
              {t('pages.study.comparison.footer.action2')}
            </Text>
          </div>
        </div>
      </div>
      <ButtonContainer>
        <Button
          strong
          iconBefore={<Icon name="rotate-ccw" size="medium" />}
          onClick={resetHistory}
        >
          <Text type="display5"> {t(`generic.dropdown.reset`)}</Text>
        </Button>
        <Button
          strong
          iconBefore={
            <Icon
              color="var(--ds-color-destructive-500)"
              name="x"
              size="medium"
            />
          }
        >
          <Text textColor="high" type="display5">
            {t(`generic.dropdown.reject`)}
          </Text>
        </Button>

        <Button
          strong
          intent={hasFindingMissingSize ? 'deactivated' : 'primary'}
          subtle={hasFindingMissingSize}
          iconBefore={<Icon name="arrow-right" size="medium" />}
          onClick={handleNext}
        >
          <Text type="display5">{t(`generic.page.next`)}</Text>
        </Button>
      </ButtonContainer>
    </>
  );
}

const ButtonContainer: React.FC = ({ children }) => {
  return (
    <div className="flex justify-center gap-xs self-end *:xl:w-150">
      {children}
    </div>
  );
};
