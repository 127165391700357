import React from 'react';
import { t, tPrint } from '@webInterfaces/I18n';
import { DialogConfirm } from '@webOrganisms/DialogConfirm/DialogConfirm';
import { Icon } from '@webMolecules/Icon/Icon';
import { titleCase } from '@helpers/string';

interface DialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  triggerBtnText: string;
}

export const MissingSizeDialog: React.FC<DialogProps> = props => {
  const { triggerBtnText } = props;
  return (
    <DialogConfirm
      {...props}
      intent="neutral"
      labelOk={t('pages.generic.modal.size.warning.button')}
    >
      <div className="mb-m flex items-center">
        <Icon name="alert-triangle" color="var(--ds-color-warning-500)" />
        <span className="ml-s block text-display2">
          {t('pages.generic.modal.size.warning.title')}
        </span>
      </div>

      <span className="text-body">
        {t('pages.generic.modal.size.warning.body1')}
        <br />
        <br />
        {tPrint(
          'pages.generic.modal.size.warning.body2',
          titleCase(triggerBtnText)
        )}
      </span>
    </DialogConfirm>
  );
};
