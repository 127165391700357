import React from 'react';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { Button } from '@webMolecules/Button/Button';
import { t } from '@webInterfaces/I18n';
import { Notification } from '@interfaces/Notifications';

const ToastNotification: React.FC<Notification> = ({
  intent,
  titleI18nKey,
  bodyI18nKey,
  cta,
}) => {
  return (
    <div className="flex">
      <div className="pr-m pt-xxxs">
        {intent === 'success' ? (
          <Icon name="check-circle" color="var(--ds-color-success-500)" />
        ) : (
          <Icon name="alert-triangle" color="var(--ds-color-destructive-500)" />
        )}
      </div>
      <div className="flex-1">
        <Text display="block" type="display5">
          {t(titleI18nKey)}
        </Text>

        {bodyI18nKey && (
          <Text display="block" marginTop="xxs" renderHtml>
            {t(bodyI18nKey)}
          </Text>
        )}

        {cta && (
          <div className="pt-m">
            <Button strong intent="neutral" onClick={cta.onClick}>
              {t(cta.labelI18nKey)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ToastNotification;
