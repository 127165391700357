import { Dispatch } from 'redux';
import { Amplify } from 'aws-amplify';
import { toApp } from '@interfaces/Root';
import { State, Actions } from '@webInterfaces/StoreTypes';
import { toProfile, toSession, toWorklist } from '@interfaces/App';
import { amplifyAppRegion } from '@helpers/env';
import { downloadStudyArchive } from '@webViewInterfaces/UseCase/Session';
import { debugDicom } from '@webViewInterfaces/UseCase/App';
import { Dicom } from '@interfaces/Dicom';
import { Workspace } from '@interfaces/Workspace';
import { updateSessionsAssignedWorkspaces } from '@webViewInterfaces/UseCase/Worklist';
import { FeatureFlags, getFeatureFlags } from '@entities/FeatureFlag';

export type DebugBar = DebugBarProps & DebugBarDispatchers;

export interface DebugBarProps {
  isDebugMode: boolean;
  debugAssetsS3Key?: string;
  sessionId?: string;
  analysisId?: string;
  debugDicom: boolean;
  featureFlags: FeatureFlags;
  dicoms: Dicom[];
  customWorkspaces: Workspace[];
}

export interface DebugBarDispatchers {
  dispatchDownloadArchive: (sessionId: string) => Actions;
  dispatchDebugDicom: () => Actions;
  dispatchUpdateSessionsAssignedWorkspaces: (
    sessionIds: string[],
    workspace: string
  ) => Actions;
}

export const dispatchers = (
  dispatch: Dispatch<Actions>
): DebugBarDispatchers => ({
  dispatchDownloadArchive: (sessionId: string) =>
    dispatch(downloadStudyArchive(sessionId)),
  dispatchDebugDicom: () => dispatch(debugDicom()),
  dispatchUpdateSessionsAssignedWorkspaces: (
    sessionIds: string[],
    workspace: string
  ) => dispatch(updateSessionsAssignedWorkspaces(sessionIds, workspace)),
});

export const fromState = (state: State): DebugBarProps => {
  const app = toApp(state).lift();
  if (!app) {
    throw new Error('App has not been initialized');
  }

  const session = toSession(app);
  const profile = toProfile(app);
  const dicoms = session.analysis.mapOr(a => a.dicoms, []);
  const isDebugMode =
    window.localStorage.getItem('use_debug_mode') === 'true' &&
    profile.email.includes('see-mode.com');
  const analysisId = session.analysis.lift()?.id;

  const s3URLPrefix = session.analysis.mapOr(a => {
    const resultBucketName = Amplify.Storage._config.AWSS3.bucket;
    const region = amplifyAppRegion;
    const studyInstanceId = a.studyUID;
    const batchId = a.batchUID;

    return `https://s3.console.aws.amazon.com/s3/buckets/${resultBucketName}?region=${region}&prefix=${profile.institutionId}/studies/${studyInstanceId}/batches/${batchId}/analyses/${analysisId}`;
  }, undefined);

  const debugAssetsS3Key = session.details.mapOr(details => {
    const sessionId = details.id;

    return s3URLPrefix ? `${s3URLPrefix}/sessions/${sessionId}/` : undefined;
  }, undefined);
  const featureFlags = getFeatureFlags();

  const customWorkspaces = toWorklist(app)
    .availableWorkspaces.second()
    .withDefault([])
    .filter(workspace => workspace.isCustomWorkspace);

  return {
    isDebugMode,
    debugAssetsS3Key,
    sessionId: session.details.lift()?.id,
    analysisId,
    debugDicom: app.debug.dicom,
    featureFlags,
    dicoms,
    customWorkspaces,
  };
};
