import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ScrollBox } from '@webMolecules/ScrollBox/ScrollBox';
import { SmallPartsStudyFindingLayout } from '../SmallPartsStudyFindingLayout';
import ComparisonLayout from '../Comparison/ComparisonLayout';
import SmallPartsSummaryTab from '../SmallPartsStudySummary/SmallPartsSummaryTab';
import { COMPARE_ROUTE, SUMMARY_ROUTE } from './SonographerNavBar';

interface SonographerSessionProps {
  tab?: string;
}

export const SonographerSession: React.FC<SonographerSessionProps> = ({
  tab,
}) => {
  const content = (() => {
    switch (tab) {
      case COMPARE_ROUTE:
        return (
          <DndProvider backend={HTML5Backend}>
            <ComparisonLayout />
          </DndProvider>
        );
      case SUMMARY_ROUTE:
        return <SmallPartsSummaryTab />;
      default:
        return <SmallPartsStudyFindingLayout />;
    }
  })();

  return (
    <ScrollBox axis="x" className="flex-auto">
      <div className="flex h-full min-w-study-layout">{content}</div>
    </ScrollBox>
  );
};
