import React from 'react';
import {
  Activity,
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  ArrowUpRight,
  ArrowDownRight,
  Bug,
  Brush,
  CalendarDays,
  CheckCircle,
  Check,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  Circle,
  ClipboardList,
  Clock,
  Download,
  Edit,
  Edit2 as EditPen,
  EyeOff,
  Eye,
  Grid,
  MousePointer,
  HelpCircle,
  Image,
  ImageOff,
  Inbox,
  Info,
  Layers,
  List,
  Lock,
  LogOut,
  Mail,
  Menu,
  Minus,
  Minimize2,
  Maximize2,
  MoreVertical,
  Move,
  Package,
  PaintBucket,
  Plus,
  RefreshCw,
  RotateCcw,
  RotateCw,
  Search,
  Settings,
  Stethoscope,
  Slash,
  Sliders,
  Square,
  Table,
  Timer,
  Trash2,
  Type,
  Upload,
  User,
  UserPlus,
  Users,
  UploadCloud,
  XCircle,
  X,
  Zap,
  ZoomIn,
  ZoomOut,
  Undo,
  Redo,
  BookmarkPlus,
  BookmarkMinus,
  PlusCircle,
  FolderInput,
  Play,
  Pause,
  StepBack,
  StepForward,
  Maximize,
  Minimize,
  Ruler,
} from 'lucide-react';
import { cnames } from '@helpers/cnames';
import styles from './icon.scss';

export const iconNames = [
  'activity',
  'alert-circle',
  'alert-triangle',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'arrow-up-right',
  'arrow-down',
  'arrow-down-right',
  'non-change',
  'asterix',
  'bug',
  'bullet-separator',
  'brush',
  'calendar-days',
  'change-icon',
  'check-circle',
  'check',
  'chevron-down-small',
  'chevron-down',
  'chevron-up',
  'chevron-left',
  'chevron-right',
  'circle-top-right',
  'circle',
  'clipboard-list',
  'clock',
  'collapse',
  'current-icon',
  'download',
  'edit',
  'edit-pen',
  'expand',
  'draw',
  'drag-handle',
  'eraser',
  'eye-off',
  'eye',
  'filter',
  'folder-input',
  'grid',
  'grid-chevron-left',
  'grid-chevron-right',
  'help-circle',
  'image',
  'image-off',
  'inbox',
  'info',
  'layers',
  'layers-dashed',
  'lifebuoy',
  'list',
  'lock',
  'logout',
  'mail',
  'maximise',
  'menu',
  'minimise',
  'minus',
  'minus-small',
  'more-vertical',
  'move',
  'move-down',
  'move-up',
  'mouse-pointer',
  'package',
  'paint-bucket',
  'pen',
  'plus',
  'plus-small',
  'plus-circle',
  'previous-icon',
  'redo',
  'refresh-cw',
  'rotate-ccw',
  'rotate-cw',
  'ruler',
  'search',
  'settings',
  'slash',
  'sliders',
  'sort',
  'sort',
  'square',
  'step-back',
  'step-forward',
  'play',
  'pause',
  'pause-circle',
  'play-circle',
  'bookmark-plus',
  'bookmark-minus',
  'symbol',
  'stethoscope',
  'table',
  'timer',
  'trash',
  'type',
  'upload',
  'user-off',
  'undo',
  'user',
  'user-plus',
  'users',
  'upload-cloud',
  'x-circle',
  'x',
  'zap',
  'zoom-in',
  'zoom-out',
  'maximize',
  'minimize',
] as const;
export type Names = (typeof iconNames)[number];

export interface IconProps {
  /**
   * The name of the icon to display.
   */
  name: Names;
  /**
   * Set the icon colour
   */
  color?: string;
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  fluid?: boolean;
}

export const Icon = ({
  name,
  color,
  size = 'medium',
  fluid = false,
}: IconProps) => {
  const classNames = cnames(styles.icon, styles[size], {
    [styles.fluid]: fluid,
  });

  return (
    <>
      {React.cloneElement(getIconForName(name), {
        className: classNames,
        color,
      })}
    </>
  );
};

const getIconForName = (name: IconProps['name']) => {
  switch (name) {
    case 'activity':
      return <Activity />;
    case 'alert-circle':
      return <AlertCircle />;
    case 'alert-triangle':
      return <AlertTriangle />;
    case 'arrow-left':
      return <ArrowLeft />;
    case 'arrow-right':
      return <ArrowRight />;
    case 'arrow-up':
      return <ArrowUp />;
    case 'arrow-up-right':
      return <ArrowUpRight />;
    case 'arrow-down':
      return <ArrowDown />;
    case 'arrow-down-right':
      return <ArrowDownRight />;
    case 'non-change':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 8.00004C2.5 7.63185 2.79848 7.33337 3.16667 7.33337L6.83333 7.33337C7.20152 7.33337 7.5 7.63185 7.5 8.00004C7.5 8.36823 7.20152 8.66671 6.83333 8.66671L3.16667 8.66671C2.79848 8.66671 2.5 8.36823 2.5 8.00004Z"
            fill="#9CB0BC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.5 8.00004C8.5 7.63185 8.79848 7.33337 9.16667 7.33337L12.8333 7.33337C13.2015 7.33337 13.5 7.63185 13.5 8.00004C13.5 8.36823 13.2015 8.66671 12.8333 8.66671L9.16667 8.66671C8.79848 8.66671 8.5 8.36823 8.5 8.00004Z"
            fill="#9CB0BC"
          />
        </svg>
      );
    case 'bug':
      return <Bug />;
    case 'bullet-separator':
      return (
        <svg
          width="6"
          height="6"
          viewBox="0 0 6 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 5.5C2.54167 5.5 2.12311 5.38826 1.74432 5.16477C1.36553 4.9375 1.0625 4.63447 0.835227 4.25568C0.611742 3.87689 0.5 3.45833 0.5 3C0.5 2.53788 0.611742 2.11932 0.835227 1.74432C1.0625 1.36553 1.36553 1.06439 1.74432 0.840909C2.12311 0.613636 2.54167 0.5 3 0.5C3.46212 0.5 3.88068 0.613636 4.25568 0.840909C4.63447 1.06439 4.93561 1.36553 5.15909 1.74432C5.38636 2.11932 5.5 2.53788 5.5 3C5.5 3.45833 5.38636 3.87689 5.15909 4.25568C4.93561 4.63447 4.63447 4.9375 4.25568 5.16477C3.88068 5.38826 3.46212 5.5 3 5.5Z"
            fill="#9CB0BC"
          />
        </svg>
      );
    case 'brush':
      return <Brush />;
    case 'calendar-days':
      return <CalendarDays />;
    case 'change-icon':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.00293 9.90009C6.91553 8.98369 7.49968 7.57712 7.49968 6C7.49968 4.42288 6.91553 3.01631 6.00293 2.09991C6.59054 1.71833 7.27245 1.5 7.99968 1.5C10.2088 1.5 11.9997 3.51472 11.9997 6C11.9997 8.48528 10.2088 10.5 7.99968 10.5C7.27245 10.5 6.59054 10.2817 6.00293 9.90009Z"
            fill="#4D6471"
          />
          <mask
            id="mask0_4673_93970"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="1"
            width="7"
            height="10"
          >
            <ellipse cx="3.5" cy="6" rx="3.5" ry="4.5" fill="#C9D4DA" />
          </mask>
          <g mask="url(#mask0_4673_93970)">
            <rect
              x="-3.87012"
              y="6.44678"
              width="14"
              height="1.5"
              transform="rotate(-45.2775 -3.87012 6.44678)"
              fill="#4D6471"
            />
            <rect
              x="-2.30469"
              y="8.98193"
              width="14"
              height="1.5"
              transform="rotate(-45.2775 -2.30469 8.98193)"
              fill="#4D6471"
            />
            <rect
              x="-0.740234"
              y="11.5176"
              width="14"
              height="1.5"
              transform="rotate(-45.2775 -0.740234 11.5176)"
              fill="#4D6471"
            />
            <rect
              x="0.824219"
              y="14.0527"
              width="14"
              height="1.5"
              transform="rotate(-45.2775 0.824219 14.0527)"
              fill="#4D6471"
            />
          </g>
        </svg>
      );
    case 'check-circle':
      return <CheckCircle />;
    case 'check':
      return <Check />;
    case 'clipboard-list':
      return <ClipboardList />;
    case 'circle':
      return <Circle />;
    case 'clock':
      return <Clock />;
    case 'chevron-right':
      return <ChevronRight />;
    case 'chevron-left':
      return <ChevronLeft />;
    case 'chevron-down':
      return <ChevronDown />;
    case 'chevron-up':
      return <ChevronUp />;
    case 'collapse':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
        >
          <path
            d="M8.5 5L1.5 5"
            stroke="#253742"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M8.5 11L5 7.5L1.5 11"
            stroke="#253742"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'current-icon':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z"
            fill="#4D6471"
          />
        </svg>
      );
    case 'download':
      return <Download />;
    case 'drag-handle':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="9" r="1"></circle>
          <circle cx="19" cy="9" r="1"></circle>
          <circle cx="5" cy="9" r="1"></circle>
          <circle cx="12" cy="15" r="1"></circle>
          <circle cx="19" cy="15" r="1"></circle>
          <circle cx="5" cy="15" r="1"></circle>
        </svg>
      );
    case 'eye-off':
      return <EyeOff />;
    case 'eye':
      return <Eye />;
    case 'edit':
      return <Edit />;
    case 'edit-pen':
      return <EditPen />;
    case 'expand':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
        >
          <path
            d="M1.5 11L8.5 11"
            stroke="#9CB0BC"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M1.5 5L5 8.5L8.5 5"
            stroke="#9CB0BC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'draw':
      return <Edit />;
    case 'grid':
      return <Grid />;
    case 'grid-chevron-right':
      return (
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 21h7v-7H3v7ZM3 10h7V3H3v7ZM16 7l5 5-5 5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'grid-chevron-left':
      return (
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 3h-7v7h7V3ZM21 14h-7v7h7v-7ZM8 17l-5-5 5-5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'help-circle':
      return <HelpCircle />;
    case 'image':
      return <Image />;
    case 'image-off':
      return <ImageOff />;
    case 'inbox':
      return <Inbox />;
    case 'info':
      return <Info />;
    case 'layers':
      return <Layers />;
    case 'layers-dashed':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2L2 7L12 12L22 7L12 2Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="1 4"
          />
          <path
            d="M2 17L12 22L22 17"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 12L12 17L22 12"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'list':
      return <List />;
    case 'lock':
      return <Lock />;
    case 'logout':
      return <LogOut />;
    case 'menu':
      return <Menu />;
    case 'more-vertical':
      return <MoreVertical />;
    case 'minus':
      return <Minus />;
    case 'package':
      return <Package />;
    case 'plus':
      return <Plus />;
    case 'plus-circle':
      return <PlusCircle />;
    case 'mouse-pointer':
      return <MousePointer />;
    case 'search':
      return <Search />;
    case 'settings':
      return <Settings />;
    case 'slash':
      return <Slash />;
    case 'sliders':
      return <Sliders />;
    case 'stethoscope':
      return <Stethoscope />;
    case 'square':
      return <Square />;
    case 'rotate-ccw':
      return <RotateCcw />;
    case 'rotate-cw':
      return <RotateCw />;
    case 'refresh-cw':
      return <RefreshCw />;
    case 'table':
      return <Table />;
    case 'timer':
      return <Timer />;
    case 'trash':
      return <Trash2 />;
    case 'type':
      return <Type />;
    case 'upload':
      return <Upload />;
    case 'user':
      return <User />;
    case 'users':
      return <Users />;
    case 'user-plus':
      return <UserPlus />;
    case 'upload-cloud':
      return <UploadCloud />;
    case 'x-circle':
      return <XCircle />;
    case 'x':
      return <X />;
    case 'zap':
      return <Zap />;
    case 'zoom-in':
      return <ZoomIn />;
    case 'zoom-out':
      return <ZoomOut />;
    case 'mail':
      return <Mail />;
    case 'maximise':
      return <Maximize2 />;
    case 'minimise':
      return <Minimize2 />;
    case 'move':
      return <Move />;
    case 'undo':
      return <Undo />;
    case 'redo':
      return <Redo />;
    case 'bookmark-plus':
      return <BookmarkPlus />;
    case 'bookmark-minus':
      return <BookmarkMinus />;
    case 'sort':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4C12.5523 4 13 4.44772 13 5V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V5C11 4.44772 11.4477 4 12 4Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 12C9 12.5523 8.55228 13 8 13L4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11L8 11C8.55228 11 9 11.4477 9 12Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 12C15 12.5523 15.4477 13 16 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L16 11C15.4477 11 15 11.4477 15 12Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29289 14.2929C7.68342 13.9024 8.31658 13.9024 8.70711 14.2929L12 17.5858L15.2929 14.2929C15.6834 13.9024 16.3166 13.9024 16.7071 14.2929C17.0976 14.6834 17.0976 15.3166 16.7071 15.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071L7.29289 15.7071C6.90237 15.3166 6.90237 14.6834 7.29289 14.2929Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7071 9.70711C16.3166 10.0976 15.6834 10.0976 15.2929 9.70711L12 6.41421L8.70711 9.70711C8.31658 10.0976 7.68342 10.0976 7.29289 9.70711C6.90237 9.31658 6.90237 8.68342 7.29289 8.29289L11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L16.7071 8.29289C17.0976 8.68342 17.0976 9.31658 16.7071 9.70711Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'chevron-down-small':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="none"
        >
          <path
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8 10l4 4 4-4"
          />
        </svg>
      );
    case 'user-off':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.46447 15.4645C5.40215 14.5268 6.67392 14 8 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H8C7.20435 16 6.44129 16.3161 5.87868 16.8787C5.31607 17.4413 5 18.2043 5 19V21C5 21.5523 4.55228 22 4 22C3.44772 22 3 21.5523 3 21V19C3 17.6739 3.52678 16.4021 4.46447 15.4645Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1213 4.93513C12.9497 3.76702 11.0503 3.76702 9.87868 4.93513C9.48816 5.3245 8.85499 5.3245 8.46447 4.93513C8.07394 4.54576 8.07394 3.91447 8.46447 3.52509C10.4171 1.57824 13.5829 1.57824 15.5355 3.52509C17.4882 5.47195 17.4882 8.62843 15.5355 10.5753C15.145 10.9647 14.5118 10.9647 14.1213 10.5753C13.7308 10.1859 13.7308 9.55462 14.1213 9.16525C15.2929 7.99713 15.2929 6.10325 14.1213 4.93513Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
            fill="currentColor"
          />
        </svg>
      );
    case 'filter':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 10C4 9.44772 4.44772 9 5 9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H5C4.44772 11 4 10.5523 4 10Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 18C8 17.4477 8.44772 17 9 17H15C15.5523 17 16 17.4477 16 18C16 18.5523 15.5523 19 15 19H9C8.44772 19 8 18.5523 8 18Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 14C6 13.4477 6.44772 13 7 13H17C17.5523 13 18 13.4477 18 14C18 14.5523 17.5523 15 17 15H7C6.44772 15 6 14.5523 6 14Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H7C6.44772 7 6 6.55228 6 6Z"
            fill="currentColor"
          />
        </svg>
      );

    case 'folder-input':
      return <FolderInput />;
    case 'asterix':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 9L9 15"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 9L15 15"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.2426 12H7.75736"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 7.75735V16.2426"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'circle-top-right':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 12C22 6.47715 17.5228 2 12 2"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'lifebuoy':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19.7782 4.22183C15.4648 -0.0915213 8.53518 -0.0915213 4.22183 4.22183C-0.0915213 8.53518 -0.0915213 15.4648 4.22183 19.7782C8.53518 24.0915 15.4648 24.0915 19.7782 19.7782C24.0915 15.4648 24.0915 8.53518 19.7782 4.22183ZM19.3539 17.0912L17.6569 16.1012C19.3539 13.6971 19.3539 10.4444 17.6569 8.04021L19.3539 7.05026C21.4752 10.0201 21.5459 14.0506 19.3539 17.0912ZM8.46447 15.5355C6.48457 13.5556 6.48457 10.4444 8.46447 8.46447C10.4444 6.48457 13.5556 6.48457 15.5355 8.46447C17.5154 10.4444 17.5154 13.5556 15.5355 15.5355C13.5556 17.5154 10.4444 17.5154 8.46447 15.5355ZM17.0205 4.57538L16.0305 6.27244C13.6263 4.57538 10.3737 4.57538 7.9695 6.27244L6.90884 4.64609C9.94939 2.45406 13.9799 2.52477 17.0205 4.57538ZM4.50467 7.05026L6.20173 8.04021C4.50467 10.4444 4.57538 13.6263 6.27244 16.0305L4.57538 17.0205C2.45406 14.0506 2.52477 10.0201 4.50467 7.05026ZM6.97955 19.4246L7.9695 17.7276C10.3737 19.4246 13.4849 19.4246 15.9598 17.7983L16.9498 19.4953C13.9799 21.4752 9.94939 21.5459 6.97955 19.4246Z" />
        </svg>
      );
    case 'eraser':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.icon}
        >
          <path
            d="M6.66669 8L16 17.3333L12.1618 21.1716C10.5997 22.7337 8.06702 22.7337 6.50493 21.1716L2.82845 17.4951C1.26636 15.933 1.26635 13.4003 2.82845 11.8382L6.66669 8Z"
            fill="currentColor"
          />
          <rect
            x="14.6667"
            y="1.41421"
            width="11.1993"
            height="18.7418"
            rx="3"
            transform="rotate(45 14.6667 1.41421)"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
      );
    case 'plus-small':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.icon}
        >
          <path
            d="M12 7V17"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 12H17"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'minus-small':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.icon}
        >
          <path
            d="M7 12H17"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'symbol':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.4286 9H22V22H9V18.4286"
            stroke="currentColor"
            strokeWidth="2"
          />
          <rect
            x="2"
            y="2"
            width="14"
            height="14"
            rx="7"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M4.05029 4.04981L13.9498 13.9493"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M13.9497 4.0498L4.05021 13.9493"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
      );
    case 'play':
      return <Play />;

    case 'play-circle':
      return (
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="64"
            height="64"
            rx="32"
            fill="#0A161D"
            fillOpacity="0.6"
          />
          <path
            d="M32 58.6666C46.7276 58.6666 58.6667 46.7276 58.6667 32C58.6667 17.2724 46.7276 5.33331 32 5.33331C17.2724 5.33331 5.33337 17.2724 5.33337 32C5.33337 46.7276 17.2724 58.6666 32 58.6666Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.6666 21.3334L42.6666 32L26.6666 42.6667V21.3334Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'pause':
      return <Pause />;

    case 'pause-circle':
      return (
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="64"
            height="64"
            rx="32"
            fill="#0A161D"
            fillOpacity="0.6"
          />
          <path
            d="M32 58.6667C46.7276 58.6667 58.6667 46.7276 58.6667 32C58.6667 17.2724 46.7276 5.33337 32 5.33337C17.2724 5.33337 5.33337 17.2724 5.33337 32C5.33337 46.7276 17.2724 58.6667 32 58.6667Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.6666 40V24"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37.3334 40V24"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'pen':
      return (
        <svg
          width="32"
          height="32"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.332 5.99912C15.5018 5.79848 15.7118 5.63516 15.9487 5.51958C16.1856 5.40399 16.4441 5.33868 16.7078 5.3278C16.9715 5.31692 17.2346 5.36071 17.4803 5.45638C17.726 5.55205 17.9489 5.69751 18.1349 5.88347C18.3209 6.06943 18.4658 6.29183 18.5604 6.53648C18.655 6.78114 18.6973 7.04268 18.6844 7.30444C18.6716 7.5662 18.604 7.82245 18.486 8.05683C18.3679 8.29122 18.202 8.49861 17.9987 8.66579L8.9987 17.6658L5.33203 18.6658L6.33203 14.9991L15.332 5.99912Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 7.33398L16.6667 10.0007"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'step-back':
      return <StepBack />;

    case 'step-forward':
      return <StepForward />;

    case 'paint-bucket':
      return <PaintBucket />;
    case 'previous-icon':
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_4673_93963)">
            <rect
              x="-4"
              y="6"
              width="14"
              height="2"
              transform="rotate(-45 -4 6)"
              fill="#4D6471"
            />
            <rect
              x="-1.17188"
              y="8.82861"
              width="14"
              height="2"
              transform="rotate(-45 -1.17188 8.82861)"
              fill="#4D6471"
            />
            <rect
              x="1.65723"
              y="11.6567"
              width="14"
              height="2"
              transform="rotate(-45 1.65723 11.6567)"
              fill="#4D6471"
            />
            <rect
              x="4.48535"
              y="14.4854"
              width="14"
              height="2"
              transform="rotate(-45 4.48535 14.4854)"
              fill="#4D6471"
            />
          </g>
          <defs>
            <clipPath id="clip0_4673_93963">
              <rect width="12" height="12" rx="6" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

    case 'maximize':
      return <Maximize />;
    case 'minimize':
      return <Minimize />;
    case 'ruler':
      return <Ruler />;
    default:
      return <RotateCcw />;
  }
};
