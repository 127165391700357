import React from 'react';
import { ArrowIcon, ArrowIconProps } from '@webMolecules/ArrowIcon/ArrowIcon';
import { labelColor } from '@webOrganisms/ResultTable/FindingsTable/Shared';

export interface ChangeLabelProps extends ArrowIconProps {
  label: string;
  intent?: 'primary' | 'secondary';
}

export const ChangeLabel: React.FC<ChangeLabelProps> = ({
  previousValue,
  currentValue,
  type = 'absolute',
  label = '',
  previousDisplayValue,
  currentDisplayValue,
  intent = 'primary',
}) => {
  let defaultDifferenceValue = undefined;

  if (currentValue != undefined && previousValue != undefined) {
    defaultDifferenceValue = currentValue - previousValue;
  }
  const bgColor = labelColor(Number(defaultDifferenceValue));

  const bgColorClassNames = {
    negative: 'bg-ui-success',
    positive: 'bg-ui-destructive',
    default: 'bg-alt-100',
  };

  if (intent == 'secondary') {
    return (
      <div
        className={`inline-flex min-w-[92px] flex-row items-center justify-center overflow-hidden rounded-m ${bgColorClassNames['default']}`}
      >
        <div className="flex p-xxxs">
          <span className="mx-xxs text-body2 text-muted">{label}</span>
          {(previousValue || currentValue) && (
            <ArrowIcon
              previousValue={previousValue}
              currentValue={currentValue}
              type={type}
              previousDisplayValue={previousDisplayValue}
              currentDisplayValue={currentDisplayValue}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`inline-flex min-w-[92px] flex-row items-center justify-center overflow-hidden rounded-m ${bgColorClassNames[bgColor]}`}
    >
      <div className="flex p-xs">
        <span className="mx-xxs text-body2 text-muted">{label}</span>
        {(previousValue || currentValue) && (
          <ArrowIcon
            previousValue={previousValue}
            currentValue={currentValue}
            type={type}
            previousDisplayValue={previousDisplayValue}
            currentDisplayValue={currentDisplayValue}
          />
        )}
      </div>
    </div>
  );
};
