import React from 'react';
import { ThemeContext, ThemeNames } from '@webAtoms/Theme/Theme';

export interface ThemeContainerProps {
  name: ThemeNames;
}

export const ThemeContainer: React.FC<ThemeContainerProps> = ({
  name,
  children,
}) => {
  return (
    <ThemeContext.Provider value={name}>
      <div data-ds-theme={name}>{children}</div>
    </ThemeContext.Provider>
  );
};
