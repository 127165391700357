import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@webMolecules/Icon/Icon';
import { Text } from '@webMolecules/Text/Text';
import {
  GridType,
  filterDicoms,
} from '@webViewModels/Pages/Study/GalleryHelper';
import { t } from '@webInterfaces/I18n';
import {
  Gallery as GalleryProps,
  getTotalFilters,
} from '@webViewModels/Pages/Study/Gallery';
import {
  eventStudyActionDecreasedGalleryResolution,
  eventStudyActionEnlargedImage,
  eventStudyActionHidDetections,
  eventStudyActionHidGallery,
  eventStudyActionIncreasedGalleryResolution,
  eventStudyActionShowedDetections,
  eventStudyActionShowedGallery,
} from '@webInterfaces/Analytics';
import { Slider } from '@webMolecules/Slider/Slider';
import { Grid } from '@webMolecules/Grid/Grid';
import { Button } from '@webMolecules/Button/Button';
import { Tooltip } from '@webMolecules/Tooltip/Tooltip';
import { selectDicoms } from '@selectors/DicomSelectors';
import { selectConfig, selectWorkflow } from '@selectors/BaseSelectors';
import { ScrollBox } from '@webMolecules/ScrollBox/ScrollBox';
import { cnames } from '@helpers/cnames';
import { Workflow } from '@entities/Workflow';
import { GalleryControls } from './GalleryControls';
import { GalleryThumbnail } from './GalleryThumbnail';
import { FindingGalleryThumbnail } from './FindingGalleryThumbnail';
import { GalleryFilter } from './GalleryFilter';

const MAXIMUM_GRID_SIZE = 7;

export const Gallery = ({
  tabName,
  loading,
  filters,
  setGalleryFilter,
  debugDicom,
  useFindingGalleryThumbnail,
  hotKeyDown,
  galleryFilterSelectOptions,
}: GalleryProps) => {
  const isFilter = getTotalFilters(filters) > 0;
  const smallScreen = window.matchMedia('(max-width: 1280px)');
  const moderateScreen = window.matchMedia('(max-width: 1600px)');
  const largeScreen = window.matchMedia('(max-width: 1980px)');
  const [view, setView] = React.useState<GridType>(GridType.grid);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [thumbnailIndex, setThumbnailIndex] = React.useState(0);
  const [gridSize, setGridSize] = React.useState(
    smallScreen.matches
      ? 1
      : moderateScreen.matches
        ? 2
        : largeScreen.matches
          ? 3
          : 4
  );

  const [showSegmentationOverlay, setShowSegmentationOverlay] =
    React.useState(true);
  const [showMeasurements, setShowMeasurements] = React.useState(false);
  const config = useSelector(selectConfig);
  const unfilteredDicoms = useSelector(selectDicoms);
  const dicoms = filterDicoms(unfilteredDicoms, filters);
  const workflow = useSelector(selectWorkflow);

  const scrollRef = React.useRef<HTMLDivElement>(null);

  const onToggleDetection = () => {
    const showDetection = !showSegmentationOverlay;
    setShowSegmentationOverlay(showDetection);

    if (showDetection) {
      eventStudyActionShowedDetections();
    } else {
      eventStudyActionHidDetections();
    }
  };

  const onToggleMeasurement = () => {
    setShowMeasurements(!showMeasurements);
  };

  const onSliderControl = (value: number) => {
    const newGridSize = MAXIMUM_GRID_SIZE - value;
    if (newGridSize > gridSize) {
      eventStudyActionDecreasedGalleryResolution();
    } else {
      eventStudyActionIncreasedGalleryResolution();
    }
    setGridSize(newGridSize);
  };

  useEffect(() => {
    setThumbnailIndex(0);
    scrollRef.current?.scrollTo(0, 0);
  }, [tabName]);

  useEffect(() => {
    if (view == GridType.grid) {
      scrollRef.current?.scrollTo(0, scrollPosition);
    } else {
      scrollRef.current?.scrollTo(0, 0);
    }
  }, [view]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUpEvent);
    return () => {
      window.removeEventListener('keyup', handleKeyUpEvent);
    };
  }, [view, thumbnailIndex]);

  const handleSetView = (type: GridType, thumbnailIndex: number) => {
    setThumbnailIndex(thumbnailIndex);
    if (view === GridType.none) {
      eventStudyActionShowedGallery();
    } else {
      switch (type) {
        case GridType.none:
          eventStudyActionHidGallery();
          break;
        case GridType.grid:
          break;
        case GridType.single:
          scrollRef.current && setScrollPosition(scrollRef.current.scrollTop);
          eventStudyActionEnlargedImage();
          break;
      }
    }
    setView(type);
  };

  const onPrevious = () => {
    if (thumbnailIndex === 0) {
      return setThumbnailIndex(dicoms.length - 1);
    }
    setThumbnailIndex(thumbnailIndex - 1);
  };

  const onNext = () => {
    if (thumbnailIndex === dicoms.length - 1) {
      return setThumbnailIndex(0);
    }
    setThumbnailIndex(thumbnailIndex + 1);
  };

  const handleKeyUpEvent = (e: KeyboardEvent) => {
    const isFieldFocused =
      document.activeElement?.tagName === 'INPUT' ||
      document.activeElement?.tagName === 'TEXTAREA';
    if (view === GridType.single && !isFieldFocused) {
      if (e.code === 'ArrowRight') {
        onNext();
      } else if (e.code === 'ArrowLeft') {
        onPrevious();
      } else if (e.code === 'Escape') {
        handleSetView(GridType.grid, thumbnailIndex);
      } else {
        return null;
      }
    }
  };

  const handleClearFilter = (filter: string) => {
    setGalleryFilter(currentFilters => {
      const findingIds = currentFilters.findingIds.filter(f => f !== filter);

      return {
        findingIds,
      };
    });
  };

  const ThumbnailComponent = useFindingGalleryThumbnail
    ? FindingGalleryThumbnail
    : GalleryThumbnail;

  const showOverlay = hotKeyDown
    ? !showSegmentationOverlay
    : showSegmentationOverlay;

  const scrollTo = React.useMemo(() => {
    // Find all elements in container which will be checked if are in view or not
    const thumbnails = (
      scrollRef.current as unknown as HTMLElement
    )?.querySelectorAll('[data-thumbnail]');

    if (thumbnails) {
      const navBar = (
        scrollRef.current as unknown as HTMLElement
      )?.getBoundingClientRect().y;

      const thumbnailArray = Array.from(thumbnails);
      const firstVisibleThumbnail = thumbnailArray.find(
        x => x.getBoundingClientRect().y >= navBar
      );

      return firstVisibleThumbnail;
    }

    return undefined;
  }, [gridSize]);

  React.useLayoutEffect(() => {
    if (scrollTo) {
      // Scroll to element with should be in view after rendering
      scrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [scrollTo, gridSize]);

  React.useEffect(() => {
    if (dicoms.length <= thumbnailIndex) {
      setThumbnailIndex(0);
    }
  }, [filters]);

  useEffect(() => {
    if (!isFilter) {
      scrollRef.current?.scrollTo(0, scrollPosition);
    }
  }, [isFilter]);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (!isFilter && view == GridType.grid) {
        setScrollPosition(event.currentTarget.scrollTop);
      }
    },
    [isFilter, view]
  );

  return (
    <div className="flex h-full flex-col">
      <div
        className="mx-xl mt-xs flex min-h-xxl items-center justify-between gap-m rounded-m bg-neutral-700 px-s py-xxs"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex items-center">
          <GalleryFilter
            selectOptions={galleryFilterSelectOptions}
            hasFilterPill={isFilter}
            filters={filters}
            thumbnailsNum={dicoms.length}
            handleClearFilter={handleClearFilter}
            setGalleryFilter={setGalleryFilter}
          />
        </div>
        <div className="flex items-center gap-m">
          {view === GridType.single ? (
            <div className="flex items-center gap-s">
              <Text
                data-testid="test-image-number"
                type="body2"
                muted
                lineClamp={1}
              >
                {dicoms.length > 0 ? thumbnailIndex + 1 : 0} / {dicoms.length}
              </Text>
              <GalleryControls onNext={onNext} onPrevious={onPrevious} />
            </div>
          ) : (
            <div className="min-w-[120px] max-w-[160px]">
              <Slider
                disabled={dicoms.length === 0}
                min={1}
                max={6}
                defaultValue={MAXIMUM_GRID_SIZE - gridSize}
                fluid
                step={1}
                onChange={value => onSliderControl(value)}
              />
            </div>
          )}
          <Tooltip
            label={
              !showOverlay
                ? t(
                    'pages.study.gallery.thumbnail.controls.layers-dashed.disabled'
                  )
                : t(
                    'pages.study.gallery.thumbnail.controls.layers-dashed.enabled'
                  )
            }
          >
            <Button
              size="small"
              data-testid="testEye"
              iconBefore={
                <Icon
                  name="layers-dashed"
                  color={
                    !showOverlay
                      ? 'var(--ds-state-color-disabled-text)'
                      : 'var(--ds-color-neutral-50)'
                  }
                />
              }
              circle
              onClick={() => onToggleDetection()}
            />
          </Tooltip>
          {config.autosizing && (
            <Tooltip
              label={
                showMeasurements
                  ? t(
                      'pages.study.gallery.thumbnail.controls.measurements.hide'
                    )
                  : t(
                      'pages.study.gallery.thumbnail.controls.measurements.show'
                    )
              }
            >
              <Button
                size="small"
                data-testid="measurement-button"
                iconBefore={
                  <Icon
                    name="ruler"
                    color={
                      !showMeasurements
                        ? 'var(--ds-state-color-disabled-text)'
                        : 'var(--ds-color-neutral-50)'
                    }
                  />
                }
                circle
                onClick={() => onToggleMeasurement()}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <ScrollBox
        ref={scrollRef}
        data-testid="gallery-wrapper"
        className={cnames('mt-xs flex-1 px-xl pb-xl', {
          'pb-xxxxl': workflow === Workflow.Radiologist, // Extra scroll padding for footer
        })}
        axis="y"
        onScroll={handleScroll}
        key={tabName + view + isFilter}
      >
        {dicoms.length > 0 ? (
          <>
            {view === GridType.grid && (
              <Grid columns={gridSize}>
                {dicoms.map((dicom, i) => (
                  <ThumbnailComponent
                    key={dicom.id}
                    dicom={dicom}
                    loading={loading}
                    handleSetView={handleSetView}
                    index={i}
                    view={view}
                    filters={filters}
                    debugDicom={debugDicom}
                    showSegmentationOverlay={showOverlay}
                    showMeasurements={showMeasurements}
                  />
                ))}
              </Grid>
            )}
            {view === GridType.single && dicoms.length > thumbnailIndex && (
              <ThumbnailComponent
                dicom={dicoms[thumbnailIndex]}
                key={dicoms[thumbnailIndex].id}
                loading={loading}
                handleSetView={handleSetView}
                index={thumbnailIndex}
                isFullscreen
                view={view}
                filters={filters}
                debugDicom={debugDicom}
                showSegmentationOverlay={showOverlay}
                showMeasurements={showMeasurements}
              />
            )}
          </>
        ) : (
          <div className="flex min-h-[60vh] flex-1 flex-col items-center justify-center rounded-m bg-alt-50 px-l py-xxxl text-center">
            <Icon name="image-off" color="var(--ds-text-color-muted)" />
            <Text muted type="body2" marginTop="m" display="block">
              {t('pages.study.gallery.empty.message')}
            </Text>
          </div>
        )}
      </ScrollBox>
    </div>
  );
};
