import React from 'react';
import { t } from '@webInterfaces/I18n';
import { titleCase } from '@helpers/string';
import {
  getI18NKeyForCharacteristics,
  ThyroidGeneralCharacteristics,
  ThyroidGeneralCharacteristicsType,
  ThyroidLobeCharacteristicsData,
} from '@entities/ThyroidGeneralCharacteristics';
import { Size, Volume } from '@entities/Finding';
import { GeneralCharacteristicsConfig } from '@config/ResultTable/Thyroid';
import { convertUnit, roundValueByUnit } from '@handlers/UnitHandler';
import { getUnitI18nKey } from '@entities/Unit';
import { NormalAbnormalValue } from '@entities/Characteristics';

export interface GeneralCharacteristicsOverlayProps {
  generalCharacteristics?: ThyroidGeneralCharacteristics;
}

const getLobeSize = (lobe: ThyroidLobeCharacteristicsData) => {
  const configUnit = GeneralCharacteristicsConfig.size.unit;
  const validSizes = [lobe.width, lobe.height, lobe.length].filter(
    size => size && size.value
  ) as Size[];

  const previewValue = validSizes.length
    ? [lobe.width, lobe.height, lobe.length]
        .map(size =>
          size && size.value
            ? roundValueByUnit(
                convertUnit(size.unit, configUnit, size.value),
                configUnit
              )
            : ' - '
        )
        .join(' x ')
    : '-';

  return Boolean(previewValue) ? (
    <div className="flex flex-row items-center">
      <span className="mr-s text-body2">{previewValue}</span>
      <span className="text-body2 text-muted">
        {t(getUnitI18nKey(configUnit))}
      </span>
    </div>
  ) : null;
};

const getVolumeText = (volume: Volume | null) => {
  const configUnit = GeneralCharacteristicsConfig.volume.unit;

  const previewValue = volume
    ? roundValueByUnit(
        convertUnit(volume.unit, configUnit, volume.value),
        configUnit
      )
    : '-';

  return (
    <>
      <span className="mr-s text-body2">{previewValue}</span>
      <span className="text-body2 text-muted">
        {t(getUnitI18nKey(configUnit))}
      </span>
    </>
  );
};

const getSizeText = (size: Size | null) => {
  const configUnit = GeneralCharacteristicsConfig.size.unit;

  const previewValue =
    size && size.value
      ? roundValueByUnit(
          convertUnit(size.unit, configUnit, size.value),
          configUnit
        )
      : '-';

  return (
    <>
      <span className="mr-s text-body2">{previewValue}</span>
      <span className="text-body2 text-muted">
        {t(getUnitI18nKey(configUnit))}
      </span>
    </>
  );
};

const getCharacteristicsText = (
  characteristics: ThyroidGeneralCharacteristicsType | null
) => {
  if (!characteristics) return '-';
  const i18NKey = getI18NKeyForCharacteristics(characteristics);

  const previewValue = i18NKey ? t(i18NKey) : '-';
  return titleCase(previewValue);
};

export const GeneralCharacteristicsOverlay: React.FC<
  GeneralCharacteristicsOverlayProps
> = ({ children, generalCharacteristics }) => {
  if (!generalCharacteristics) return <>{children}</>;

  return (
    <div className="w-full px-xs pt-xs" onClick={e => e.stopPropagation()}>
      <div className="pointer-events-none grid grid-cols-[100px_0.8fr_2fr_1fr] gap-x-[3px] gap-y-[15px]">
        <div></div>
        <span className="text-display3">
          {t('config_key.result_table.thyroid_finding.options.body_side_right')}
        </span>
        <div className="flex justify-center">
          <span className="text-display3">
            {t(
              'config_key.result_table.thyroid_finding.options.body_side_median'
            )}
          </span>
        </div>
        <span className="text-display3">
          {t('config_key.result_table.thyroid_finding.options.body_side_left')}
        </span>

        <span className="text-display6">
          {t('result_table.heading.key.size')}
        </span>
        <div>{getLobeSize(generalCharacteristics.rightLobe)}</div>

        <div className="relative col-start-3 row-start-2 row-end-7 flex justify-center">
          <div className="absolute">
            {getSizeText(generalCharacteristics.isthmusThickness)}
          </div>

          {children}
        </div>
        <div>{getLobeSize(generalCharacteristics.leftLobe)}</div>

        <span className="text-display6">
          {t('result_table.heading.key.volume')}
        </span>
        <div>{getVolumeText(generalCharacteristics.rightLobe.volume)}</div>
        <div>{getVolumeText(generalCharacteristics.leftLobe.volume)}</div>

        <span className="text-display6">
          {t('result_table.heading.key.vascularity')}
        </span>
        {[
          generalCharacteristics.rightLobe,
          generalCharacteristics.leftLobe,
        ].map((lobe, i) => {
          return (
            <span className="text-body2" key={i}>
              {getCharacteristicsText(lobe.vascularity)}
            </span>
          );
        })}

        <span className="text-display6">
          {t('result_table.heading.key.echotexture')}
        </span>
        {[
          generalCharacteristics.rightLobe,
          generalCharacteristics.leftLobe,
        ].map((lobe, i) => {
          return (
            <span className="text-body2" key={i}>
              {getCharacteristicsText(lobe.echotexture)}
            </span>
          );
        })}

        <span className="text-display6">
          {t('study.result_table.retrosternal_ext')}
        </span>
        {[
          generalCharacteristics.rightLobe,
          generalCharacteristics.leftLobe,
        ].map((lobe, i) => {
          return (
            <span className="text-body2" key={i}>
              {getCharacteristicsText(lobe.retrosternal)}
            </span>
          );
        })}
      </div>

      <div className="mt-s flex gap-x-m text-nowrap border-t border-weak py-s">
        <div className="border-r border-strong pr-m">
          <span className="mr-s text-display6">
            {t('study.result_table.tracheal_deviation')}
          </span>
          <span className="text-body2">
            {getCharacteristicsText(generalCharacteristics.trachealDeviation)}
          </span>
        </div>

        <div className="truncate">
          <span className="mr-s text-display6">
            {t('study.result_table.lymph_nodes_normality')}
          </span>
          <span className="text-body2">
            {getCharacteristicsText(generalCharacteristics.lymphNodes)}
          </span>
          {generalCharacteristics.lymphNodes == NormalAbnormalValue.Abnormal &&
            generalCharacteristics.abnormalLymphNodesText && (
              <span className="text-body2">
                {`, ${generalCharacteristics.abnormalLymphNodesText}`}
              </span>
            )}
        </div>
      </div>
    </div>
  );
};
