import React from 'react';
import { useSelector } from 'react-redux';
import { selectSession } from '@selectors/SessionSelectors';
import { useImageContext } from '@hooks/useImageContext';
import { ThemeContainer } from '@webAtoms/Theme/ThemeContainer';
import {
  eventFinaliseSession,
  eventStudyActionDownloadedReport,
} from '@webInterfaces/Analytics';
import { sessionUseCase } from '@useCases/Session';
import { useDispatcher } from '@helpers/useDispatcher';
import { dispatch } from '@webInterfaces/Store';
import { PDFTriggerAction } from '@entities/Session';
import SmallPartsStudyWorkSheet from './OffScreenWorkSheetPDFGenerator/SmallPartsStudyWorkSheet';

export interface ReportPDFContextProps {
  increaseLoadingCanvas: () => void;
  decreaseLoadingCanvas: () => void;
}

const ReportPDFContext = React.createContext<ReportPDFContextProps>({
  increaseLoadingCanvas: () => null,
  decreaseLoadingCanvas: () => null,
});

export const ReportPDFContextProvider: React.FC = ({ children }) => {
  const worksheetPDFRef = React.createRef<HTMLDivElement>();

  const { isLoadingImages } = useImageContext();
  const { pdfTriggerAction } = useSelector(selectSession);

  const dispatchGeneratePDF = useDispatcher(sessionUseCase.GeneratePDF);
  const dispatchGeneratedPDFStored = useDispatcher(
    sessionUseCase.GeneratePDFStored
  );
  const dispatchFinaliseSession = useDispatcher(sessionUseCase.FinaliseSession);

  const [pdfIsReady, setPdfIsReady] = React.useState<boolean>(false);
  const [numLoadingCanvas, setNumLoadingCanvas] = React.useState(0);

  const resetPDFStatus = () => {
    setPdfIsReady(false);
  };

  const increaseLoadingCanvas = () => {
    setNumLoadingCanvas(prev => prev + 1);
  };

  const decreaseLoadingCanvas = () => {
    setNumLoadingCanvas(prev => prev - 1);
  };

  const completeInitial = () => {
    if (worksheetPDFRef.current) {
      dispatchGeneratedPDFStored({
        htmlElement: worksheetPDFRef.current,
        useSinglePage: true,
        initial: true,
      });
    }
  };

  const completeDownloadPDF = () => {
    if (worksheetPDFRef.current) {
      eventStudyActionDownloadedReport();
      dispatchGeneratePDF({
        htmlElement: worksheetPDFRef.current,
        saveOnDisk: true,
        saveOnRemote: false,
        useSinglePage: true,
        onUpdate: dispatch,
      });
    }
  };

  const completeFinalise = () => {
    if (worksheetPDFRef.current) {
      eventFinaliseSession();
      dispatchFinaliseSession({
        htmlElement: worksheetPDFRef.current,
        useSinglePage: true,
        onUpdate: dispatch,
      });
    }
  };

  React.useEffect(() => {
    setPdfIsReady(numLoadingCanvas === 0);
  }, [numLoadingCanvas]);

  React.useEffect(() => {
    if (!isLoadingImages && pdfIsReady) {
      switch (pdfTriggerAction) {
        case PDFTriggerAction.Initial:
          completeInitial();
          break;
        case PDFTriggerAction.Download:
          completeDownloadPDF();
          break;
        case PDFTriggerAction.Finalise:
          completeFinalise();
          break;
        case PDFTriggerAction.None:
        default:
          break;
      }
      resetPDFStatus();
    }
  }, [pdfTriggerAction, pdfIsReady, isLoadingImages]);

  return (
    <ReportPDFContext.Provider
      value={{
        increaseLoadingCanvas,
        decreaseLoadingCanvas,
      }}
    >
      {children}
      <div
        className="pointer-events-none absolute bottom-0 h-0 overflow-hidden"
        data-testid="test-worksheetContainer"
      >
        <ThemeContainer name={'light'}>
          <div ref={worksheetPDFRef}>
            {pdfTriggerAction !== PDFTriggerAction.None && (
              <SmallPartsStudyWorkSheet />
            )}
          </div>
        </ThemeContainer>
      </div>
    </ReportPDFContext.Provider>
  );
};

export default ReportPDFContext;
