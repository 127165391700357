import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@webMolecules/Text/Text';
import { Drawer } from '@webMolecules/Drawer/Drawer';
import { t } from '@webInterfaces/I18n';
import { FindingSelectPanel } from '@webOrganisms/FindingSelectPanel/FindingSelectPanel';
import { selectFindingTableConfig } from '@selectors/SessionSelectors';
import { MenuSelect } from '@webMolecules/MenuSelect/MenuSelect';
import { useDispatcher } from '@helpers/useDispatcher';
import { findingUseCase } from '@useCases/Finding';
import { selectFindings } from '@selectors/FindingSelectors';
import { FindingFilterType } from '@config/ResultTable/FindingTable';
import { FindingSortType } from '@entities/InstitutionConfiguration';

export const FindingFilterBar: React.FC = ({}) => {
  const [selectMode, setSetSelectMode] = useState(false);
  const findings = useSelector(selectFindings);

  const findingTableConfig = useSelector(selectFindingTableConfig);

  const findingFilters = findingTableConfig?.findingFilters ?? [];
  const findingSortOptions = findingTableConfig?.sortOptions ?? [];

  const dispatchFilterFindings = useDispatcher(findingUseCase.FilterFindings);
  const dispatchSortFindings = useDispatcher(findingUseCase.SortFindings);

  const includedIds = findings.filter(f => f.included).map(f => f.id);

  const totalCount = findings.length;

  const filterOptions = findingFilters.map(({ filterType, labelKey }) => ({
    label: t(labelKey),
    value: filterType,
  }));

  return (
    <>
      <div
        className="mt-xs flex min-h-xxl items-center justify-start gap-l rounded-m bg-neutral-700 px-s py-xxs"
        onClick={e => e.stopPropagation()}
      >
        <div>
          <Text type="display5">{t('pages.study.finding.filter.total')}</Text>
          <Text type="body2">&nbsp;({totalCount}):</Text>
        </div>

        <div
          data-testid="finding-label"
          className="flex flex-1 items-center gap-xs"
        >
          <Text type="display6">{t('pages.study.finding.filter.label')}</Text>
          <div className="min-w-100 max-w-[180px] flex-1 *:text-muted">
            <MenuSelect
              data-testid="finding-filter-select"
              options={filterOptions}
              size="small"
              placeholder="Select"
              placeholderDisabled
              value=""
              onChange={value => {
                if (value === FindingFilterType.IncludeSelected) {
                  setSetSelectMode(true);
                } else {
                  dispatchFilterFindings({
                    filterType: value as FindingFilterType,
                  });
                }
              }}
            />
          </div>
          <Text type="display6" marginLeft="l">
            {t('pages.study.finding.sort.label')}
          </Text>
          <div className="min-w-100 max-w-[180px] flex-1 *:text-muted">
            <MenuSelect
              options={findingSortOptions.map(option => ({
                label: t(option.labelKey),
                value: option.sortType,
              }))}
              size="small"
              placeholder="Select"
              placeholderDisabled
              value=""
              onChange={value => {
                dispatchSortFindings({ sortType: value as FindingSortType });
              }}
            />
          </div>
        </div>
        {/* {workflow == Workflow.Radiologist && (
          <div className="flex">
            <UndoRedoTool iconOnly={true} />
          </div>
        )} */}
      </div>
      <Drawer isOpen={selectMode} width="44vw">
        {selectMode && (
          <FindingSelectPanel
            initialSelectedIds={includedIds}
            findings={findings}
            handleClose={() => setSetSelectMode(false)}
          />
        )}
      </Drawer>
    </>
  );
};

export default FindingFilterBar;
