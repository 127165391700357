import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Header } from '@webOrganisms/Header/Header';
import { LoadingIcon } from '@webMolecules/LoadingIcon/LoadingIcon';
import { selectProfile } from '@selectors/BaseSelectors';
import { useDispatcher } from '@helpers/useDispatcher';
import { authenticateUseCase } from '@useCases/Authentication';
import {
  selectIsSessionLoading,
  selectComparisonStatus,
} from '@selectors/SessionSelectors';
import { LoadingLayout } from '@webPages/Study/LoadingLayout';
import { Footer } from '@webMolecules/Footer/Footer';
import { ImageProvider } from '@hooks/useImageContext';
import { ComparisonStatus } from '@webViewModels/Pages/Study/Shared';
import { ScrollBox } from '@webMolecules/ScrollBox/ScrollBox';
import DebugBar from '../DebugBar';
import ComparisonLayout from '../Comparison/ComparisonLayout';
import { StudyTitle } from '../Sonographer/StudyTitle';
import { RadiologyFooter } from './RadiologyFooter';
import { RadiologySession } from './RadiologySession';

export const RadiologyLayout: React.FC = () => {
  const me = useSelector(selectProfile);
  const isLoading = useSelector(selectIsSessionLoading);
  const comparisonStatus = useSelector(selectComparisonStatus);

  const onSignOut = useDispatcher(authenticateUseCase.SignOut);

  const isComparisonAvailable =
    comparisonStatus !== ComparisonStatus.Unavailable;
  const [isComparisonPage, setComparisonPage] = useState(false);
  const togglePage = () => setComparisonPage(!isComparisonPage);

  return (
    <ImageProvider>
      <div className="flex h-screen flex-col">
        <DebugBar />
        <Header
          profile={me}
          sticky={false}
          onSignOut={onSignOut}
          showSearchBar={false}
        >
          {isLoading ? (
            <div className="flex items-center">
              <LoadingIcon />
            </div>
          ) : (
            <StudyTitle />
          )}
        </Header>
        {isLoading ? (
          <LoadingLayout />
        ) : (
          <>
            <div data-testid="page-loaded-indicator" className="inline" />
            <ScrollBox axis="xy" className="flex-auto">
              <div className="flex h-full min-w-study-layout">
                {isComparisonPage ? (
                  <DndProvider backend={HTML5Backend}>
                    <ComparisonLayout />
                  </DndProvider>
                ) : (
                  <RadiologySession />
                )}
              </div>
              <div className="flex w-full justify-center">
                <Footer>
                  <RadiologyFooter
                    isComparisonAvailable={isComparisonAvailable}
                    isComparisonPage={isComparisonPage}
                    togglePage={togglePage}
                  />
                </Footer>
              </div>
            </ScrollBox>
          </>
        )}
      </div>
    </ImageProvider>
  );
};
