import React from 'react';
import { connect } from 'react-redux';
import { useDndScrolling } from 'react-dnd-scrolling';
import { ScrollBox } from '@webMolecules/ScrollBox/ScrollBox';
import * as ViewModel from '@webViewModels/Pages/Study/Comparison';
import { ComparisionArea } from '@webOrganisms/ResultTable/Comparison/ComparisonArea';
import { UnassignedFindingSection } from '@webOrganisms/ResultTable/Comparison/UnassignedFindingSection';
import { t } from '@webInterfaces/I18n';
import { ExpandButton } from '@webOrganisms/ExpandButton/ExpandButton';
import { Icon } from '@webMolecules/Icon/Icon';
import { ComparisonDiagramSection } from '@webOrganisms/ResultTable/Comparison/ComparisonDiagram';
import { Divider } from '@webMolecules/Divider/Divider';
import { MiniCanvasProvider } from '../MiniCanvasContext';
import styles from './comparisonLayout.scss';

const ComparisonLayout: React.FC<ViewModel.Comparison> = ({
  sessionId,
  sessionDate,
  comparisonSessionDate,
}) => {
  const [isExpandAll, setIsExpandAll] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);

  useDndScrolling(ref, {});

  return (
    <MiniCanvasProvider key={sessionId}>
      <ScrollBox
        ref={ref}
        axis="y"
        className={`relative w-full p-m ${styles.comparisonPanel} pb-xxxl`}
      >
        <ComparisonDiagramSection
          currentDate={sessionDate}
          previousDate={comparisonSessionDate}
        />
        <Divider color="var(--ds-border-color-weak)" />

        <div className="relative flex items-center justify-center">
          <span className="absolute left-0 p-xs text-display5 text-muted">
            {t(`pages.study.comparison.header.label`)}
          </span>
          <div className="my-s flex max-w-200 items-center">
            <ExpandButton
              isOpen={isExpandAll}
              type="horizontal"
              onClick={() => setIsExpandAll(!isExpandAll)}
              iconAfter={
                <Icon
                  size="extraSmall"
                  name={!isExpandAll ? 'expand' : 'collapse'}
                />
              }
            >
              <span className="text-display6">
                {isExpandAll
                  ? t(`pages.study.comparison.collapse_all.button`)
                  : t(`pages.study.comparison.expand_all.button`)}
              </span>
            </ExpandButton>
          </div>

          {/* {workflow == Workflow.Radiologist && (
            <div className="absolute right-0 flex min-[1360px]:pr-[140px]">
              <UndoRedoTool />
            </div>
          )} */}
        </div>

        <ComparisionArea isExpandAll={isExpandAll} />
        <UnassignedFindingSection isExpanded={isExpandAll} />
      </ScrollBox>
    </MiniCanvasProvider>
  );
};

export default connect(ViewModel.fromState)(ComparisonLayout);
