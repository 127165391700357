import React from 'react';
import { Text } from '@webMolecules/Text/Text';
import { Icon } from '@webMolecules/Icon/Icon';
import { t } from '@webInterfaces/I18n';

export const ToastUploadErrorBackend: React.FC = () => {
  return (
    <div className="flex">
      <div className="pr-m pt-xxxs">
        <Icon name="alert-triangle" color="var(--ds-color-destructive-500)" />
      </div>
      <div className="flex-1">
        <div className="mb-s flex items-center justify-between">
          <div className="flex">
            <div>
              <Text display="block" type="display3">
                {t('uploads.status.error')}
              </Text>

              <Text display="block" marginTop="xxs">
                {t('uploads.status.error_processing')}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
