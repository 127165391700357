import React from 'react';
import { connect, useSelector } from 'react-redux';
import * as ViewModel from '@webViewModels/Pages/Study/SmallPartsSummaryTab';
import { t } from '@webInterfaces/I18n';
import { Icon } from '@webMolecules/Icon/Icon';
import { Popover } from '@webMolecules/Popover/Popover';
import { Button } from '@webMolecules/Button/Button';
import { TiRadModal } from '@webOrganisms/TiRadModal/TiRadModal';
import { InfoButton } from '@webMolecules/InfoButton/InfoButton';
import {
  selectFindingTableConfig,
  selectImpressions,
  selectStudyType,
} from '@selectors/SessionSelectors';
import { ThemeContainer } from '@webAtoms/Theme/ThemeContainer';
import { selectIncludedFindings } from '@selectors/FindingSelectors';
import { StudyType } from '@entities/StudyType';
import { selectConfig } from '@selectors/BaseSelectors';
import { ScrollBox } from '@webMolecules/ScrollBox/ScrollBox';
import SmallPartsStudyWorkSheet from '../OffScreenWorkSheetPDFGenerator/SmallPartsStudyWorkSheet';
import { MiniCanvasProvider } from '../MiniCanvasContext';
import { FinaliseButton } from '../Sonographer/FinaliseButton';
import { formatFindings } from './summaryHelper';

const SmallPartsSummaryTab: React.FC<ViewModel.SmallPartsSummaryTab> = ({
  metadata,
}) => {
  const studyImpressions = useSelector(selectImpressions);
  const includedFindings = useSelector(selectIncludedFindings);
  const institutionConfig = useSelector(selectConfig);
  const findingConfig = useSelector(selectFindingTableConfig);
  const studyType = useSelector(selectStudyType);

  if (!findingConfig) return null;
  const findings = formatFindings(
    includedFindings,
    findingConfig.config,
    institutionConfig
  );

  return (
    <MiniCanvasProvider>
      <div className="flex w-full">
        <ScrollBox
          axis="y"
          className="relative border-r border-r-weak px-xl py-m last:border-none"
        >
          <div className="flex w-full flex-col">
            {studyType === StudyType.SmallPartsThyroid && (
              <div className="absolute left-1/2 z-50 mt-xl -translate-x-1/2">
                <TiRadModalButton />
              </div>
            )}

            <div className="m-auto">
              <ThemeContainer name={'light'}>
                <SmallPartsStudyWorkSheet />
              </ThemeContainer>
            </div>
          </div>
        </ScrollBox>

        <ScrollBox
          className="relative flex-1 border-r border-r-weak pl-s pr-xl pt-m last:border-none"
          axis="y"
        >
          <div className="flex h-full flex-col">
            <div className="flex-1">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <div className="mr-s">
                    <Icon
                      name="stethoscope"
                      size="large"
                      color="var(--ds-text-color-muted)"
                    />
                  </div>
                  <span className="block text-display2">
                    {t('pages.study.worksheet.heading.radiologist_report')}
                  </span>
                  <div className="flex flex-1 justify-end">
                    <Popover
                      trigger={
                        <Button
                          iconBefore={
                            <Icon
                              name="help-circle"
                              color="var(--ds-text-color-muted)"
                            />
                          }
                          size="small"
                          circle
                        />
                      }
                      position="right-start"
                      size="medium"
                      showArrow
                    >
                      <div className="flex flex-col gap-xs">
                        <span className="text-body2">
                          {t(
                            'study.summary_tab.notes.impression.message.line1'
                          )}
                        </span>
                      </div>
                    </Popover>
                  </div>
                </div>

                <SummarySection headingKey="pages.study.worksheet.heading.clinical_findings">
                  <span className="text-body">
                    {metadata.decryptedClinicalFindings || ''}
                  </span>
                </SummarySection>

                <SummarySection headingKey="pages.study.worksheet.label.findings">
                  <ul>
                    {findings.map((finding, i) => {
                      return (
                        <li className="ml-l list-disc" key={i}>
                          <span className="block text-body2">{finding}</span>
                        </li>
                      );
                    })}
                  </ul>
                </SummarySection>

                <SummarySection headingKey="pages.study.worksheet.label.study_impressions">
                  <span className="block whitespace-pre-wrap text-body2">
                    {studyImpressions}
                  </span>
                </SummarySection>
              </div>
            </div>

            <div className="sticky bottom-0 flex h-fit w-full flex-col items-center bg-vignette-bottom pb-m pt-xl text-center">
              <div className="w-200">
                <FinaliseButton />
              </div>
              <span className="text-caption text-primary">
                {t('pages.study.worksheet.quality_control_msg')}
              </span>
            </div>
          </div>
        </ScrollBox>
      </div>
    </MiniCanvasProvider>
  );
};

export default connect(
  ViewModel.fromState,
  ViewModel.dispatchers
)(SmallPartsSummaryTab);

interface SummarySectionProps {
  headingKey: string;
}

const SummarySection: React.FC<SummarySectionProps> = ({
  children,
  headingKey,
}) => {
  return (
    <div className="ml-[4.4rem] flex flex-col gap-xs py-s">
      <span className="block text-display3">{t(headingKey)}</span>
      {children}
    </div>
  );
};

const TiRadModalButton = ({}) => {
  const [showModal, setShowModal] = React.useState(false);

  <TiRadModal isOpen={showModal} onClose={() => setShowModal(false)} />;

  return (
    <div className="flex justify-between gap-s">
      <InfoButton
        type="modal"
        icon="info"
        component={TiRadModal}
        label={t(`tirad.modal.heading`)}
      />
    </div>
  );
};
